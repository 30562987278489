.btn:active {
  transform: scale(0.95);
}

.bnt {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-property: opacity, transform, color;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px;
}
