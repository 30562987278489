/* stylelint-disable property-no-vendor-prefix */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  line-height: 1.5 !important;
  @apply text-main-airdrop !important;
}

[type='button'] {
  appearance: none;
}

a {
  color: inherit;
  text-decoration: none !important;
}

a:hover {
  color: '#76cb9f' !important;
}

* {
  box-sizing: border-box;
}

.hideNumberInputArrows::-webkit-outer-spin-button,
.hideNumberInputArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideNumberInputArrows[type='number'] {
  -moz-appearance: textfield;
}

/* stylelint-disable-next-line selector-class-pattern */
.highcharts-tooltip-container {
  z-index: 9999 !important;
}

.hideScrollBar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hideScrollBar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.apple-anime-container {
  position: absolute;
  z-index: -1;
  transition: opacity 0.25s ease-in-out 0s;
  pointer-events: none;
  display: flex;
  transform: scaleX(1);
  opacity: 1;
  inset: -100px;
}

.apple-anime-inside {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 100%;
}

.apple-anime-first {
  top: 9%;
  bottom: 9%;
  position: absolute;
  left: 0px;
  right: 0px;
  background: radial-gradient(
    rgba(255, 215, 15, 0.1) 30%,
    rgba(255, 95, 15, 0) 70%
  );
  z-index: -1;
  animation: 8s linear 0s infinite reverse none running typ-k-jKHfrQ;
  pointer-events: none;
}

.apple-anime-second {
  top: 6%;
  bottom: 6%;
  position: absolute;
  left: 0px;
  right: 0px;
  background: radial-gradient(
    rgba(255, 15, 175, 0.14) 30%,
    rgba(255, 15, 175, 0) 70%
  );
  z-index: -1;
  animation: 14s linear 0s infinite reverse none running typ-k-jKHfrQ;
  pointer-events: none;
}

.apple-anime-third {
  top: 6%;
  bottom: 6%;
  position: absolute;
  left: 0px;
  right: 0px;
  background: radial-gradient(
    rgba(15, 163, 255, 0.22) 30%,
    rgba(15, 163, 255, 0) 70%
  );
  animation: 12s linear 0s infinite normal none running typ-k-jKHfrQ;
  pointer-events: none;
}

.fancyText {
  background-clip: text;
  background-image: linear-gradient(180deg, #b1ffd6, #76cb9f);
}

input {
  caret-color: #76cb9f;
}

@keyframes typ-k-jKHfrQ {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

button {
  @apply cursor-pointer;
}

button:disabled {
  @apply cursor-not-allowed;
}

.reactSelect__input {
  box-shadow: none !important;
}

.range-slider {
  height: 2px !important;
  @apply bg-secondary-gray;
}

.range-slider .range-slider__range {
  @apply bg-main-color !important;
}
.range-slider__range[data-active] {
  /* CSS for active (actively being dragged) range */
}
.range-slider .range-slider__thumb {
  @apply bg-main-color !important;
  width: 16px;
  height: 16px;
}
.range-slider__thumb[data-lower] {
  /* CSS for lower thumb */
}
.range-slider__thumb[data-upper] {
  /* CSS for upper thumb */
}
.range-slider__thumb[data-active] {
  /* CSS for active (actively being dragged) thumbs */
}
.range-slider__thumb[data-disabled] {
  /* CSS for disabled thumbs */
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.header-mask::before {
  --header-height: 56px;
  backdrop-filter: blur(12px);
  -webkit-mask-image: linear-gradient(
    to bottom,
    black var(--header-height),
    transparent
  );
  content: '';
  position: absolute;
  inset: -1px 0px -50%;
  pointer-events: none;
  user-select: none;
}

.header-mask::after {
  content: '';
  position: absolute;
  inset: -1px 0px -50%;
  pointer-events: none;
  user-select: none;
}

.starry-sky {
  margin: -128px auto -200px auto;
  --color: #76cb9f;
  pointer-events: none;
  user-select: none;
  position: relative;
  width: min(1280px, 100%);
  height: 600px;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(
    circle at center center,
    black,
    transparent 80%
  );
}

.starry-sky-airdrop {
  margin: -128px auto -200px auto;
  --color: #76cb9f;
  pointer-events: none;
  user-select: none;
  position: relative;
  width: min(1280px, 100%);
  height: 600px;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(
    circle at center center,
    black,
    transparent 80%
  );
}

@media screen and (max-width: 768px) {
  .starry-sky,
  .starry-sky-airdrop {
    margin: -258px auto;
  }
}

.starry-sky::before {
  content: '';
  position: absolute;
  inset: 0px;
  background: radial-gradient(
    circle at bottom center,
    var(--color),
    transparent 70%
  );
  opacity: 0.4;
}

.starry-sky::after {
  content: '';
  position: absolute;
  background: rgb(6 12 26);
  width: 200%;
  left: -50%;
  aspect-ratio: 1 / 0.7;
  border-radius: 50%;
  border-top: 1px solid rgba(118, 203, 159, 0.4);
  top: 50%;
}

.starry-sky-airdrop::before {
  content: '';
  position: absolute;
  inset: 0px;
  background: radial-gradient(
    circle at bottom center,
    var(--color),
    transparent 70%
  );
  opacity: 0.4;
}

.starry-sky-airdrop::after {
  content: '';
  position: absolute;
  background: rgb(19 18 29);
  width: 200%;
  left: -50%;
  aspect-ratio: 1 / 0.7;
  border-radius: 50%;
  border-top: 1px solid rgba(118, 203, 159, 0.4);
  top: 50%;
}

.rta__entity {
  background: transparent !important;
  @apply py-2 px-2 border-b border-main-border !important;
}
.rta__entity--selected {
  background: transparent !important;
  @apply bg-gray-2 !important;
}

.rta__list {
  @apply bg-gray-3 border border-main-border rounded-md shadow-lg !important;
  overflow-y: auto;
  max-height: 200px;
}

.rta__autocomplete {
  z-index: 9999;
}

.hr {
  height: 1px;
  border: 0;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5) 50%,
    hsla(0, 0%, 100%, 0)
  );
}

.personalPro {
  position: absolute;
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 10px;
  background: none;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 13px;
}

.personalPro::before {
  position: absolute;
  padding: 1.5px;
  border-radius: inherit;
  background: #76cb9f;
  content: '';
  inset: 0;
  -webkit-mask: linear-gradient(#000, #000) content-box,
    linear-gradient(#000, #000);
  mask: linear-gradient(#000, #000) content-box, linear-gradient(#000, #000);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.heyLini::before {
  content: '';
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(rgb(46 53 72 / 100%), rgb(34 40 56 / 0%) 120%);
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
}

.heyLiniAfter::after {
  content: '';
  position: absolute;
  pointer-events: none;
  user-select: none;
  inset: 0px;
  opacity: 0;
  background: radial-gradient(
    ellipse 50% 50% at center,
    rgb(118 203 159 / 10%),
    rgba(255, 255, 255, 0)
  );
  transition: opacity 960ms ease 0s;
  opacity: 0.8;
}

.heyLiniGrain,
.heyLiniGrainBtn {
  z-index: 1;
  position: absolute;
  inset: 0px;
  -webkit-mask-image: linear-gradient(black, transparent);
}

.heyLiniGrainBtn {
  -webkit-mask-image: linear-gradient(black, black);
}

.heyLiniGrain::before,
.heyLiniGrainBtn::before {
  content: '';
  position: absolute;
  inset: 0px;
}
.heyLiniGrain::before {
  background: rgba(255, 255, 255, 0);
}
.heyLiniGrainBtn::before {
  background: rgb(120 120 120 / 10%);
}

.heyLiniGrain::after,
.heyLiniGrainBtn::after {
  content: '';
  position: absolute;
  inset: 0px;
  opacity: 0.2;
  filter: url(#noiseFilter);
}

.grainFilter {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  word-wrap: normal;
}

.heyLiniGradient {
  background: linear-gradient(transparent, rgb(6 12 26 / 50%));
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.header {
  -webkit-box-decoration-break: clone;
  -webkit-text-fill-color: transparent;
  color: unset;
  animation: 1000ms ease 0s 1 normal backwards running gisjuz;
  background: -webkit-linear-gradient(
    left bottom,
    rgb(255, 255, 255) 30%,
    rgb(255 255 255 / 58%)
  );
  -webkit-background-clip: text;
}

@keyframes gisjuz {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.userReview {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
}

.userReview::before {
  content: '';
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.07)
  );
  -webkit-mask: linear-gradient(black, black) content-box content-box,
    linear-gradient(black, black);
  -webkit-mask-composite: xor;
}

.text-white {
  color: rgb(247, 248, 248);
}

.landingBtn {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px;
}

.plan {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  font-size: 15px;
  font-weight: 500;
  gap: 24px;
  line-height: 22px;
  min-width: 380px;
  max-width: 420px;
}

.planHighlighted {
  position: relative;
  border: 4px solid transparent;
  border-radius: 12px;
  background-clip: padding-box;
  padding: 64px 40px;
  min-width: 388px;
}

@media screen and (max-width: 1200px) {
  .plan,
  .planHighlighted {
    min-width: auto;
  }
}

@media screen and (max-width: 860px) {
  .plan,
  .planHighlighted {
    min-width: 100%;
  }
}

.plan strong {
  color: #fff;
  font-weight: 500;
}

.plan hr {
  height: 1px;
  border: 0;
  margin: 24px calc(-1 * var(--plan-padding));
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.2) 50%,
    hsla(0, 0%, 100%, 0)
  );
}

.plan .icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: inherit;
}

.planHighlighted::before {
  position: absolute;
  z-index: -1;
  border-radius: inherit;
  margin: calc(-1 * 4px);
  background: linear-gradient(0.44turn, #76cb9f, #063735);
  box-shadow: 0 0 48px rgba(0, 0, 0, 0.6);
  content: '';
  inset: 0;
}

.oneTime {
  align-self: flex-start;
  background: linear-gradient(
    100.41deg,
    #76cb9f 0.52%,
    #a6ffaf 23.61%,
    #b6ffbd 50%,
    #d8ffe1 75%,
    #8dffca 100.52%
  );
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 32px;
  font-weight: 600;
  -webkit-text-fill-color: transparent;
}

.liniGradient {
  background: linear-gradient(107.56deg, #eaffee, #76cb9f 1%, #103a39),
    hsla(0, 0%, 100%, 0.1);
}

.presale {
  background: linear-gradient(180deg, #b1ffd6, #76cb9f);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 14px;
  font-weight: 600;
  -webkit-text-fill-color: transparent;
  margin-bottom: 4px;
}

label {
  user-select: none;
}

/* div[role='combobox'],
.rs-picker-toggle,
.rs-picker-default,
.rs-picker-toggle {
  background-color: transparent !important;
} */

.rs-picker-toggle {
  --rs-input-bg: transparent;
  background-color: var(--rs-input-bg) !important;
}

.rs-picker-default .rs-picker-toggle {
  border-color: #33323e !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
}

.rs-picker-default .rs-picker-toggle {
  border-color: #2d2d2d !important;
}

.rs-picker-toggle-textbox::placeholder {
  color: #fff;
}

.rs-picker-toggle-value {
  color: #fff !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #fff !important;
}

.rs-picker-menu {
  z-index: 35 !important;
  background-color: #181622 !important;
  border: 1px solid #33323e;
  color: white;
}

.rs-calendar-table-cell-un-same-month {
  color: #a1a0ab;
}

.rs-calendar-time-dropdown-column {
  padding: 0 2px;
}

.rs-picker-toggle .rs-stack .rs-stack-item {
  width: auto;
}

.rs-picker-daterange-panel {
  width: 100%;
}

.rs-stack {
  width: auto;
}

.rs-stack-item {
  width: 100%;
}

.rs-picker-toolbar-right {
  text-align: right;
}

.rs-picker-daterange-header {
  border-color: #33323e !important;
  color: #a1a0ab;
}

.rs-calendar-table-header-cell-content {
  color: #a1a0ab !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #76cb9f !important;
}

.rs-btn-subtle {
  color: #a1a0ab !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: #76cb9f !important;
  color: #fff !important;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #76cb9f33 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #76cb9f !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #76cb9f !important;
}

.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  background-color: #76cb9f !important;
  color: white !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: #76cb9f !important;
  color: white !important;
}

.rs-picker-toolbar {
  border-color: #33323e !important;
}

.rs-picker-toolbar {
  max-width: 100% !important;
}

.rs-calendar-month-dropdown {
  background-color: #181622 !important;
  border-color: #33323e !important;
}

.rs-calendar-time-dropdown-cell {
  color: #a1a0ab !important;
  border-radius: 6px;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px solid #33323e !important;
}

.rs-calendar-time-dropdown,
.rs-calendar-time-dropdown-column-title,
.rs-calendar-time-dropdown-column-title {
  background-color: #181622 !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #76cb9f !important;
  color: #fff !important;
}

.rs-calendar-time-dropdown-cell:hover {
  background-color: #76cb9f !important;
  color: #fff !important;
}

.rs-calendar-month-dropdown-year-active {
  color: #76cb9f !important;
}

.rs-calendar-btn-close {
  background-color: #181622 !important;
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: #76cb9f;
}

.rs-btn-primary {
  background-color: #76cb9f !important;
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: #76cb9f !important;
}

.rs-picker-toggle-caret {
  color: #fff !important;
}

p + p {
  margin: 0 !important;
}

.progress-circle {
  position: relative;
  /* so that children can be absolutely positioned */
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: #13121d;
  border-radius: 50%;
  line-height: 40px;
  min-width: 40px;
}

.progress-circle:after {
  border: none;
  position: absolute;
  top: 2px;
  left: 2px;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #13121d;
  content: ' ';
}

/* Text inside the control */

.progress-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  color: #53777a;
  z-index: 2;
}

.left-half-clipper {
  /* a round circle */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  /* needed for clipping */
  clip: rect(0, 40px, 40px, 20px);
  /* clip: rect(auto, auto, auto, auto); */
}

/* when p>50, don't clip left half*/

.progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}

.value-bar {
  position: absolute;
  clip: rect(0, 20px, 40px, 0);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 9px solid #76cb9f; */
  border-width: 9px;
  box-sizing: border-box;
}

/* Progress bar filling the whole right half for values above 50% */

.progress-circle.over50 .first50-bar {
  position: absolute;
  clip: rect(0, 40px, 40px, 20px);
  /* background-color: #76cb9f; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.progress-circle:not(.over50) .first50-bar {
  display: none;
}
